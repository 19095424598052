import  React from "react";
import { Component } from "react";

export default class About extends Component {
    render() {
        return (
        <div className="container">
        <h1>About</h1>
        <p> <br />
<small>BloodMu is free Mu server, beta testing / in development<br/>
OpenMu - <a href="https://github.com/MUnique/OpenMU" target={"_blank"}>https://github.com/MUnique/OpenMU</a>
</small>
        </p>
        </div>);
    }
}
